import clsx from "clsx";

import type { FullButtonProps } from "./Button";
import styles from "./button.module.css";

export const buttonClassname = ({
  color,
  textColor,
  outlined,
  rounded,
  grow,
  wideAndNarrow,
  fontSize,
  shadow,
  className,
}: FullButtonProps) => {
  const colorClass = color ? styles[color] : styles.transparent;
  const sizeClass = fontSize ? styles[fontSize] : styles.medium;
  const textColors =
    textColor === "white" ? styles.whiteText : styles.blackText;
  const outlinedClass = outlined ? styles.outlined : styles["not-outlined"];
  const growClass = grow && styles.grow;
  const roundedClass = rounded && styles.rounded;
  const wideAndNarrowClass = wideAndNarrow && styles.wideAndNarrow;
  const overrideClass = className;
  const shadowClass = shadow && styles.shadow;
  const comboClasses = () => {
    const comboStyles: string[] = [];
    if (color === "white" && !outlined) comboStyles.push(styles.shadow);
    if (!rounded && !wideAndNarrow) comboStyles.push(styles.standard);
    return comboStyles.join(" ");
  };
  return clsx(
    styles.button,
    colorClass,
    textColors,
    outlinedClass,
    roundedClass,
    growClass,
    wideAndNarrowClass,
    sizeClass,
    shadowClass,
    comboClasses(),
    overrideClass
  );
};
