import UI from "./UI";

interface SkeletonProps {
  height: string;
  absolute?: boolean;
}
const Skeleton = ({ height, absolute }: SkeletonProps) => {
  return <UI height={height} absolute={absolute} />;
};

export default Skeleton;
