import styles from "./quantity-selector.module.css";
const QuantitySelector = ({
  quantity,
  increment,
  decrement,
}: {
  quantity: number;
  increment: () => void;
  decrement: () => void;
}) => {
  return (
    <span className={styles["quantity-container"]}>
      <button onClick={decrement}>-</button>
      <p>{quantity}</p>
      <button onClick={increment}>+</button>
    </span>
  );
};

export default QuantitySelector;
