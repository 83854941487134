import { useLayoutEffect, useState } from "react";

const useHasHydrated = () => {
  const [hasHydrated, setHasHydrated] = useState(false);
  const useBrowserLayoutEffect =
    typeof window !== "undefined" ? useLayoutEffect : () => {};

  useBrowserLayoutEffect(() => {
    setHasHydrated(true);
  }, []);

  return hasHydrated;
};

export default useHasHydrated;
