import { type HTMLAttributes } from "react";

type PageProps = HTMLAttributes<HTMLDivElement> & {
  as?: "main" | "section" | "div" | "nav";
};

const Page = ({ as = "main", ...props }: PageProps) => {
  const PageAs = as;
  return <PageAs data-layout="page" {...props} />;
};

export default Page;
