"use client";
import Link, { type LinkProps } from "next/link";
import { type HTMLAttributes } from "react";

import type { ButtonProps } from "../Button/Button";
import { buttonClassname } from "../Button/buttonClassname";

/**
 * A button that looks like a link and navigates to a specified URL when clicked.
 * Inherits all props from `ButtonProps` and `LinkProps`.
 */

type Props = ButtonProps &
  LinkProps &
  HTMLAttributes<HTMLAnchorElement> & { target?: string };

const ButtonLink = ({
  color,
  textColor,
  outlined,
  rounded,
  grow,
  fontSize,
  wideAndNarrow,
  className,
  ...props
}: Props) => {
  return (
    <Link
      className={buttonClassname({
        color,
        textColor,
        outlined,
        rounded,
        grow,
        fontSize,
        wideAndNarrow,
        className
      })}
      {...props}
    />
  );
};

export default ButtonLink;
