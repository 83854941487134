"use client";
import ReactPlayer from "react-player/lazy";

import useHasHydrated from "@/lib/hooks/useHasHydrated";

import { useEffect, useRef, useState } from "react";
import Skeleton from "../Skeleton";

const Video = (props: { url: string; [key: string]: unknown }) => {
  const containerRef = useRef(null);
  const hasHydrated = useHasHydrated();
  /* Fix cover by setting relative zoom based on video aspect */
  const [aspect, setAspect ] = useState(0.56);
  const getVimeoAspect = async () => {
    const response = await fetch("https://vimeo.com/api/oembed.json?url=" + props.url);
    const videodata = await response.json();
    if(videodata && videodata.width) {
      setAspect(videodata.height / videodata.width);
    }
  }
  useEffect(() => {
    getVimeoAspect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    /* object fit hack */
    if (containerRef.current) {
      const element = containerRef.current;
      new ResizeObserver(() => {
        const containerApect = element.offsetHeight / element.offsetWidth;
        const zoom =
          containerApect > aspect
            ? containerApect / aspect
            : aspect / containerApect;
        element.style.cssText =
          "height: 100%; width: 100%; --video-zoom: " +
          zoom +
          "; --video-width:" +
          element.offsetWidth +
          "; --video-height:" +
          element.offsetHeight;
      }).observe(element);
    }
  }, [hasHydrated, aspect]);
  /* -----  */
  if (!props.url || !hasHydrated) return <Skeleton height="100%" />;
  return (
    <div style={{ width: "100%", height: "100%" }} ref={containerRef}>
      <ReactPlayer
        {...props}
        playsinline
        autoPlay
        muted
        playing
        className="react-player"
        loop
        config={{
          vimeo: {
            playerOptions: {
              autoplay: true,
              loop: true,

              responsive: true,
              playsinline: true,
              background: true,
              muted: true,
            },
          },
        }}
        fallback={<Skeleton height="100%" />}
      />
    </div>
  );
};

export default Video;
