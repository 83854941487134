import clsx from "clsx";

import styles from "./heading.module.css";

type Props = React.HTMLAttributes<HTMLHeadingElement> & HeadingProps;
type HeadingProps = {
  as: HeadingTypes | "p" | "span";
  styledAs: HeadingTypes | TittleTypes;
  italic?: boolean;
};
type HeadingTypes = "h1" | "h2" | "h3" | "h4" | "h5";
type TittleTypes = "t1" | "t2" | "t3" | "t4";

export const headingClassname = (
  props: HeadingProps & { className?: string | undefined }
) => {
  const styledAsClass = props.styledAs ?? "h1";
  const overrideClass = props?.className;
  const italicClass = props.italic ? "italic" : "";
  return clsx(styles.root, styledAsClass, overrideClass, italicClass);
};

const Heading = ({ as, styledAs, italic, className, ...props }: Props) => {
  const HeadingType = as;
  return (
    <HeadingType
      className={headingClassname({
        as,
        styledAs,
        italic,
        className,
      })}
      {...props}
    />
  );
};

export default Heading;
