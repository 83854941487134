import clsx from "clsx";

type Props = React.HTMLAttributes<HTMLHeadingElement> & BodyProps;

type BodyProps = {
  as: "p" | "span" | "strong" | "div";
  styledAs?: "b1" | "b2" | "b3" | "b4";
  uppercase?: boolean;
  italic?: boolean;
};

export const bodyClassname = ({
  styledAs,
  uppercase,
  italic,
  className,
}: {
  styledAs?: "b1" | "b2" | "b3" | "b4";
  uppercase?: boolean;
  italic?: boolean;
  className?: string;
}) => {
  const styledAsClass = styledAs ?? "b1";
  const uppercaseClass = uppercase ? "uppercase" : undefined;
  const italicClass = italic ? "italic" : undefined;
  const overrideClass = className;

  return clsx(
    styledAsClass,
    uppercaseClass,
    italicClass,
    uppercaseClass,
    overrideClass
  );
};

const Body = ({
  as,
  styledAs,
  uppercase,
  italic,
  className,
  ...props
}: Props) => {
  const BodyType = as;
  return (
    <BodyType
      className={bodyClassname({ styledAs, uppercase, italic, className })}
      {...props}
    />
  );
};

export default Body;
