"use client";

import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import type { HTMLAttributes } from "react";
import { type PropsWithChildren } from "react";

const AspectRatioProvider = ({
  children,
  height,
  width,
  ...props
}: PropsWithChildren<{
  height: number;
  width: number;
}> &
  HTMLAttributes<HTMLDivElement>) => {
  return (
    <AspectRatio.Root ratio={width / height} {...props}>
      {children}
    </AspectRatio.Root>
  );
};

export default AspectRatioProvider;
