import clsx from "clsx";

type Props = React.HTMLAttributes<HTMLDivElement> & {
  as?: "p" | "div" | "span" | "h1" | "h2" | "h3" | "h4" | "h5";
  uppercase?: boolean;
  noBrackets?: boolean;
};

export const preambleClassname = ({
  uppercase,
  className,
  noBrackets,
}: Props) => {
  const uppercaseClass = () => uppercase && "uppercase";
  const overrideClass = className;

  return clsx(
    noBrackets ? "preamble__noBrackets" : "preamble",
    uppercaseClass,
    overrideClass,
  );
};

const Preamble = ({ className, uppercase, noBrackets, ...props }: Props) => {
  const SubtitleClass = props.as || "p";
  return (
    <SubtitleClass
      className={preambleClassname({ className, uppercase, noBrackets })}
      {...props}
    />
  );
};

export default Preamble;
