import ClientInnerHTML from "./ClientInnerHTML";

const InnerHTML = ({
  html,
  type = "html",
  className,
  ...rest
}: {
  html: string;
  type?: "html" | "script";
  className?: string;
}) => {
  if (type === "html")
    return (
      <div
        {...rest}
        className={className}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );

  if (type === "script")
    return <ClientInnerHTML className={className} {...rest} html={html} />;

  return null;
};

export default InnerHTML;
