"use client";

import { type ButtonHTMLAttributes, forwardRef } from "react";

import { buttonClassname } from "./buttonClassname";

export type FullButtonProps = ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;
export type ButtonProps = {
  color?: "white" | "black" | "gray" | "beige" | "transparent";
  fontSize?: "small" | "medium" | "large";
  textColor?: "white" | "black";
  outlined?: boolean;
  rounded?: boolean;
  wideAndNarrow?: boolean;
  grow?: boolean;
  shadow?: boolean;
};

const Button = forwardRef<HTMLButtonElement, FullButtonProps>(
  (
    {
      color,
      textColor,
      outlined,
      rounded,
      grow,
      fontSize,
      wideAndNarrow,
      className,
      shadow,
      ...props
    }: FullButtonProps,
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={buttonClassname({
          color,
          textColor,
          outlined,
          rounded,
          grow,
          fontSize,
          wideAndNarrow,
          className,
          shadow,
        })}
        {...props}
      />
    );
  }
);

Button.displayName = "Button";
export default Button;
