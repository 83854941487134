// @ts-nocheck
import AspectRatioProvider from "@/components/modifiers/AspectRatioProvider";
import type { GeneratedCartItem } from "@/lib/centra/localAtomics";
import type {
  CartItemMaybeHooked,
  CartItemWithHooks,
} from "@/lib/hooks/useSelection/cart/useItems";
import clsx from "clsx";
import Image from "next/image";

import Link from "next/link";
import QuantitySelector from "../QuantitySelector/QuantitySelector";
import styles from "./cart-item.module.css";

const CartItemPrice = ({
  discountedPrice,
  price,
}: {
  discountedPrice?: string;
  price?: string;
}) => {
  const isDiscounted = discountedPrice !== price;
  return (
    <div className={clsx(styles.discounted__wrapper)}>
      {isDiscounted && (
        <span className={styles.discounted__price}>{discountedPrice}</span>
      )}
      <p className={styles.regular__price}>{price}</p>
    </div>
  );
};

const getProductPageHref = (item: CartItemMaybeHooked | GeneratedCartItem) => {
  return "/product/" + item.uri;
};

const CartImage = ({
  item,
}: {
  item: CartItemMaybeHooked | GeneratedCartItem;
}) => {
  return (
    <Link className={styles.image} href={getProductPageHref(item)}>
      <AspectRatioProvider width={1} height={1.5}>
        <Image src={item?.media?.full?.href || ""} fill alt={""} />
      </AspectRatioProvider>
    </Link>
  );
};

const CartItemQuantity = ({
  item,
}: {
  item: CartItemWithHooks | undefined;
}) => {
  if (!item) return null;
  return (
    <div className={styles.quantity__wrapper}>
      <QuantitySelector
        quantity={item.quantity}
        increment={item.increment}
        decrement={item.decrement}
      />
    </div>
  );
};

const DeleteCartItem = ({ item }: { item: CartItemWithHooks | undefined }) => {
  if (!item) return null;
  return (
    <button onClick={item.remove}>
      <span className={styles.remove__button}>Delete</span>
    </button>
  );
};

const CartItem = ({
  item,
  price = true,
}: {
  item: CartItemMaybeHooked | GeneratedCartItem;
  price?: boolean;
}) => {
  const isCartItemWithHooks = (
    item: CartItemMaybeHooked | GeneratedCartItem,
  ): item is CartItemWithHooks => {
    return (item as CartItemWithHooks).increment !== undefined;
  };
  const isWithHooks = isCartItemWithHooks(item);
  if (!item?.name) {
    return null;
  }

  return (
    <div className={styles.item}>
      <CartImage item={item} />

      <div className={styles.details__container}>
        <div className={styles.left__column}>
          <Link href={getProductPageHref(item)}>
            <strong className={styles.item__name}>{item.name}</strong>
          </Link>

          <p>{"Color: " + item.variant}</p>
          <p>{"Size: " + item.size}</p>
          {item?.giftbox && <p>With giftbox</p>}

          {isWithHooks && (
            <button
              onClick={item.moveToWishlist}
              className={styles["move-button"]}
            >
              <span className={styles["move-button-span"]}>
                Move to wishlist
              </span>
            </button>
          )}
        </div>
        {price && (
          <div className={styles.right__column}>
            <CartItemPrice
              discountedPrice={item.discountedPrice}
              price={item.price}
            />

            <CartItemQuantity item={isWithHooks ? item : undefined} />

            <DeleteCartItem item={isWithHooks ? item : undefined} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CartItem;
