import styles from "./skeleton.module.css";

interface UIProps {
  height: string;
  absolute?: boolean;
}

const UI = ({ height, absolute }: UIProps) => {
  return (
    <div
      className={styles.skeleton}
      style={{
        animation: "skeleton-loading 1s linear infinite alternate",
        height,
        position: absolute ? "absolute" : "relative",
      }}
    ></div>
  );
};

export default UI;
