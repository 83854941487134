import clsx from "clsx";

type Props = React.HTMLAttributes<HTMLHeadingElement> & {
  as: "p" | "span" | "strong" | "div";
  styledAs: "menu-text-A" | "menu-text-B";
  uppercase?: boolean;
  italic?: boolean;
};

export const menuTextClassname = (props: Props) => {
  const styledAsClass = () => {
    switch (props.styledAs) {
      case "menu-text-A":
        return "menu-text-A";
      case "menu-text-B":
        return "menu-text-B";
      default:
        return "menu-text-A";
    }
  };
  const uppercaseClass = () => {
    if (props.uppercase) return "uppercase";
  };
  const italicClass = () => {
    if (props.italic) return "italic";
  };
  const overrideClass = () => {
    if (props.className) return props.className;
  };
  return clsx(
    styledAsClass(),
    uppercaseClass(),
    italicClass(),
    uppercaseClass(),
    overrideClass()
  );
};

const MenuText = (props: Props) => {
  const HeadingType = props.as;
  return <HeadingType className={menuTextClassname(props)} {...props} />;
};

export default MenuText;
