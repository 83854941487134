"use client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useRouter } from "next/navigation";

import { signUpUser } from "@/lib/centra/selectionFunctions";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";

interface ISignupError extends Error {
  response: {
    status: number;
  };
}

export default function useSignUpUser() {
  const queryClient = useQueryClient();
  const router = useRouter();
  const customerToken = usePersistedStore((state) => state.token);
  const signUp = useMutation(
    (variables: {
      email: string;
      password: string;
      firstName: string;
      lastName: string;
      newsletter?: boolean;
    }) => signUpUser(variables, customerToken),
    {
      onError: async (error: ISignupError) => {
        if (window.CentraCheckout) window.CentraCheckout.resume();
        return error;
      },
      onSuccess: async (data) => {
        queryClient.setQueryData(["selection"], data);
        Cookies.set("token", data.token, {
          expires: 365,
          secure: true,
          sameSite: "strict",
        });
        queryClient.invalidateQueries(["selection"]);
        router.refresh();
      },
    }
  );

  return { signUp };
}
