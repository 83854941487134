import clsx from "clsx";

type Props = React.HTMLAttributes<HTMLHeadingElement> & {
  as: "p" | "span" | "strong" | "div";
  styledAs: "tag-A" | "tag-B" | "tag-C";
  uppercase?: boolean;
  italic?: boolean;
};

export const tagTextClassname = (props: Props) => {
  const styledAsClass = () => {
    switch (props.styledAs) {
      case "tag-A":
        return "tag-A";
      case "tag-B":
        return "tag-B";
      case "tag-C":
        return "tag-C";
      default:
        return "tag-A";
    }
  };
  const uppercaseClass = () => {
    if (props.uppercase) return "uppercase";
  };
  const italicClass = () => {
    if (props.italic) return "italic";
  };
  const overrideClass = () => {
    if (props.className) return props.className;
  };
  return clsx(
    styledAsClass(),
    uppercaseClass(),
    italicClass(),
    uppercaseClass(),
    overrideClass()
  );
};

const TagText = (props: Props) => {
  const HeadingType = props.as;
  return <HeadingType className={tagTextClassname(props)} {...props} />;
};

export default TagText;
