"use client";

import clsx from "clsx";
import type { HTMLProps } from "react";

import Close from "@/components/icons/Close";
import Search from "@/components/icons/Search";

import styles from "./search-bar.module.css";

const SearchBar = (
  props: HTMLProps<HTMLInputElement> & {
    onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
    onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onButtonClick?: () => void;
    isModalOpen?: boolean;
    clearValue?: () => void;
    closeModal?: () => void;
  }
) => {
  return (
    <form
      className={clsx(
        styles["search-form"],
        props.isModalOpen && styles.active
      )}
      onSubmit={(e) => {
        e.preventDefault();
        if (props.onSubmit) props?.onSubmit(e);
      }}
    >
      <input
        type="text"
        className={`${props.value ? styles.active : ""}`}
        value={props.value}
        onChange={(e) => props.onValueChange(e)}
        {...props}
      />
      {!props?.value && (
        <button
          type="button"
          onClick={() => {
            if (props.clearValue) props.clearValue();
            if (props.closeModal) props.closeModal();
          }}
        >
          <Close />
        </button>
      )}
      {props?.value && (
        <button
          type="submit"
          onClick={() => {
            if (props.onButtonClick) props.onButtonClick();
          }}
        >
          <Search />
        </button>
      )}
    </form>
  );
};

export default SearchBar;
