"use client";

import clsx from "clsx";
import Link, { type LinkProps } from "next/link";
import { type HTMLAttributes } from "react";

import styles from "./link.module.css";

type Props = _LinkProps &
  LinkProps &
  HTMLAttributes<HTMLAnchorElement> & { target?: string };
export type _LinkProps = {
  size?: "small" | "medium" | "large";
  color?: "dark" | "light";
  pseudo?: boolean;
  underlined?: boolean;
  icon?: "plus";
};
const defaultProps = {
  size: styles.small,
  color: styles.dark,
  underlined: false
};
export const linkClassname = ({
  size,
  color,
  underlined,
  className
}: _LinkProps & { className?: string }) => {
  const colorClass = () => (color && styles[color]) ?? defaultProps.color;
  const sizeClass = () => {
    if (!underlined && size) return styles[size];
  };
  const underlinedClass = underlined ? styles.underlined : "";
  return clsx(
    styles.base,
    colorClass(),
    sizeClass(),
    underlinedClass,
    className
  );
};

const _Link = ({
  href,
  size,
  color,
  underlined,
  className,
  children,
  icon,
  pseudo,
  ...props
}: Props) => {
  const Icon = () => {
    switch (icon) {
      case "plus":
        return "+ ";
      default:
        return null;
    }
  };
  if (pseudo) {
    return (
      <div
        className={linkClassname({
          size,
          color,
          underlined,
          className
        })}
      >
        <span className={styles.span__base}>
          <Icon />
          {children}
        </span>
      </div>
    );
  }
  return (
    <Link
      href={href}
      className={linkClassname({
        size,
        color,
        underlined,
        className
      })}
      {...props}
    >
      <span className={styles.span__base}>
        <Icon />
        {children}
      </span>
    </Link>
  );
};

export default _Link;
