"use client";

import { useEffect, useRef } from "react";
interface DangerouslySetHtmlContentProps {
  html: string;
  _dangerouslySetInnerHTML?: string;
  className?: string;
}
function DangerouslySetHtmlContent({
  html,
  _dangerouslySetInnerHTML,
  className,
  ...rest
}: DangerouslySetHtmlContentProps) {
  // We remove 'dangerouslySetInnerHTML' from props passed to the div

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (html && divRef.current) {
      const slotHtml = document.createRange().createContextualFragment(html); // Create a 'tiny' document and parse the html string
      divRef.current.innerHTML = ""; // Clear the container
      divRef.current.appendChild(slotHtml); // Append the new content
    }
  }, [html, divRef]);

  return (
    <div
      {...rest}
      className={className}
      ref={divRef}
      style={{
        display: "contents",
      }}
    >
      <p>{html}</p>
    </div>
  );
}

export default DangerouslySetHtmlContent;
