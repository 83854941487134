import clsx from "clsx";
import Link from "next/link";

import Page from "../Page";
import styles from "./breadcrumbs.module.css";

export type BreadcrumbType = {
  name: string;
  uri: string;
  disabled?: boolean;
};
const Breadcrumbs = ({
  breadcrumbs,
  className,
  asDiv,
  small,
  ...props
}: {
  breadcrumbs?: BreadcrumbType[];
} & React.HTMLAttributes<HTMLDivElement> & {
    asDiv?: boolean;
    small?: boolean;
  }) => {
  if (small)
    return (
      <div className={clsx(styles["small-container"], className)} {...props}>
        {breadcrumbs?.map((breadcrumb, i) => (
          <>
            <Link
              href={breadcrumb?.uri}
              className={clsx(
                "underline-hover",
                breadcrumb.disabled && "disabled"
              )}
            >
              {breadcrumb?.name}
            </Link>
            {i < breadcrumbs.length - 1 && <span> / </span>}
          </>
        ))}
      </div>
    );

  return (
    <Page
      as={asDiv ? "div" : "main"}
      className={clsx(styles.container, className)}
      {...props}
    >
      {breadcrumbs?.map((breadcrumb, i) => (
        <>
          <Link
            href={breadcrumb?.uri}
            className={clsx(
              "underline-hover",
              breadcrumb.disabled && "disabled"
            )}
          >
            {breadcrumb?.name}
          </Link>
          {i < breadcrumbs.length - 1 && <span> / </span>}
        </>
      ))}
    </Page>
  );
};

export default Breadcrumbs;
