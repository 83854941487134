import type { PropsWithChildren } from "react";

import type { ProductCard } from "@/lib/centra/formatters/formatProductCards/formatProductCard";

import ToolTip from "../ToolTip";
import styles from "./product-card.module.css";

const UI = ({ product }: { product: ProductCard }) => {
  return (
    <div className={styles.swatch__tooltip}>
      {product?.description?.variants &&
        product?.description?.variants
          .filter(
            (variant) =>
              variant?.swatch?.code !== product.description?.swatch?.code
          )
          .map((variant, i) => (
            <div
              className={styles.swatch}
              style={{ background: `#${variant?.swatch?.code ?? "333"}` }}
              key={variant?.name + "__extra__swatch__" + i}
            />
          ))}
    </div>
  );
};

const PCToolTip = ({
  product,
  children,
}: PropsWithChildren<{ product: ProductCard }>) => {
  if (
    product?.description?.variants &&
    product?.description?.variants?.length > 1
  )
    return <ToolTip Content={<UI product={product} />}>{children}</ToolTip>;
  else return <>{children}</>;
};

export default PCToolTip;
