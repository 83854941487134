"use client";

import * as Acc from "@radix-ui/react-accordion";
import clsx from "clsx";

import styles from "./accordion.module.css";

/**
 * Accordion component that uses Radix UI's Accordion components.
 * @param title - The title of the accordion.
 * @param children - The content of the accordion.
 * @param styleProps - An optional object containing CSS classes for the accordion, trigger, and content.
 * @param icon - An optional icon to display next to the title.
 * @returns A React component representing an accordion.
 */

const Accordion = ({
  title,
  children,
  styleProps,
  icon,
}: {
  title: string;
  children: React.ReactNode;
  styleProps?: {
    accordion?: string;
    trigger?: string;
    content?: string;
  };
  icon?: React.ReactNode;
}) => {
  return (
    <Acc.Item
      value={title}
      className={clsx(styles.accordion, styleProps?.accordion)}
    >
      <Acc.AccordionTrigger
        className={clsx(styles.trigger, styleProps?.trigger)}
      >
        {title}
        {icon}
      </Acc.AccordionTrigger>
      <Acc.AccordionContent
        className={clsx(styles.content, styleProps?.content)}
      >
        {children}
      </Acc.AccordionContent>
    </Acc.Item>
  );
};

export default Accordion;
